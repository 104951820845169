import { useEffect } from "react";
import { connect } from "react-redux";

export const Timer = (props: any) => {
  const { stopConnection, secs, setSecs } = props;

  function updateTime() {
    setSecs((seconds: any) => seconds - 1);
  }

  useEffect(() => {
    const token = setTimeout(updateTime, 1000);

    return function cleanUp() {
      clearTimeout(token);
    };
  });

  useEffect(() => {
    if (secs == 0) {
      stopConnection();
    }
  }, [secs]);

  return (
    <p
      style={{
        color: "white",
        marginTop: "15px",
        fontWeight: "bolder",
        fontSize: "20px",
      }}
    >
      {secs}
    </p>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Timer);
