import { MESSAGE_RECEIVED } from "../actions/types";

const initialState: any = {
  error: false,
  loading: true,
  message: "",
  messageArgs: {},
};

export default function messageReducer(state = initialState, action: any) {
  const { type, payload } = action;
  switch (type) {
    case MESSAGE_RECEIVED:
      return {
        ...state,
        message: payload.content,
        error: false,
        loading: false,
        messageArgs: payload.args,
      };
    default:
      return state;
  }
}
