export const USER_LOADED = "USER_LOADED";
export const USER_LOADING = "USER_LOADING";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";

export const LODING_EVENTS = "LODING_EVENTS";
export const EVENTS_LOADED = "EVENTS_LOADED";
export const EVENTS_ERROR = "EVENTS_ERROR";

export const LOAD_ONE_EVENT = "LOAD_ONE_EVENT";

export const MESSAGE_RECEIVED = "MESSAGE_RECEIVED";
export const GET_INTER_ACTIVE_ACTIONS = 'GET_INTER_ACTIVE_ACTIONS'