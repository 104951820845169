import { connect } from "react-redux";
import { GiCancel } from "react-icons/gi";
export const Filters = ({
  singleEvent,
  sendMessage,
  setSecs,
  setFiltersApper,
}: any) => {
  const clickedFilter = (title: string, key: any) => {
    sendMessage(`Filter ${key + 1}`);
    setSecs(25);
  };

  const closeFilterPanel = () => {
    sendMessage("ClosePanel");
    setFiltersApper(false);
    setSecs(15);
  };
  return (
    <>
      <div className=" text-left">
        <GiCancel
          color="var(--secColor)"
          size="40"
          style={{ cursor: "pointer" }}
          onClick={() => closeFilterPanel()}
        />
      </div>

      {singleEvent.filters?.map((filter: any, index: any) => {
        return (
          <img
            onClick={() => clickedFilter(filter.name, index)}
            key={index}
            src={filter?.icon_url}
            className="mx-2 filtersLogo my-3"
          />
        );
      })}
    </>
  );
};

const mapStateToProps = ({}: any) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Filters);
