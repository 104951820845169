import React, { useEffect } from "react";
import { connect } from "react-redux";

import { Button, Form, FormGroup, Input } from "reactstrap";
import { login } from "../../../store/actions/authAction";
import { useHistory } from "react-router-dom";
import Logo from "../../../assets/login/Group 31.png";
export const Login = ({ login, auth }: any) => {
  const [errors, setErrors] = React.useState({
    phone: false,
    password: false,
  });
  let history = useHistory();
  const [loginData, setLoginData] = React.useState<any>({
    phone: "",
    password: "",
  });
  const { phone, password } = loginData;
  const InputOnChange = (e: any) => {
    const { name, value } = e.target;
    setLoginData({ ...loginData, [name]: value });
  };

  const onSubmit = (e: any) => {
    e.preventDefault();
    login(loginData);
  };
  useEffect(() => {
    if (auth.token) {
      return history.push("/");
    }
  }, [auth.token]);
  return (
    <div className="centeredForm" id="centeredForm">
      <Form className="login-form" onSubmit={onSubmit}>
        <div>
          <img src={Logo} style={{ height: "170px" }} />
        </div>
        <FormGroup className="my-3">
          <Input
            placeholder="Username"
            name="phone"
            onChange={InputOnChange}
            type="text"
            className="login-input-username"
            value={phone}
          />
        </FormGroup>
        <FormGroup className="my-3">
          <Input
            name="password"
            onChange={InputOnChange}
            placeholder="Password"
            type="password"
            className="login-input-password"
            value={password}
          />
        </FormGroup>
        <FormGroup className="my-3">
          <Button className="mainButton px-5">Login</Button>
        </FormGroup>
      </Form>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  auth: state.auth,
});

const mapDispatchToProps = {
  login,
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
