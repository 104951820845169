import { combineReducers } from "redux";
import { reducer as toastrReducer } from "react-redux-toastr";
import authReducer from "./authReducer";
import eventsReducer from "./eventsReducer";
import messageReducer from "./messageReducer";
export default combineReducers({
  toastr: toastrReducer,
  auth: authReducer,
  events: eventsReducer,
  messaging: messageReducer,
});
