import React from "react";
import { connect } from "react-redux";

import { getFiltersForOneEvent } from "../../store/actions/eventsAction";
import SendModal from "../SendModal";

import qs from "qs";
import Loader from "../Loader";

import InterActiveComponent from "../InterActiveComponent";
import Actions from "../Actions";
import Filters from "../Filters";
import { PassCode } from "../PassCode";

export const SplashComponent = ({
  sendMessage,
  events,
  singleEvent,
  getFiltersForOneEvent,
  location,
  messaging,
  stopConnection,
  interActiveAds,
  loading: reduxLoading,
  setSecs,
  secs,
}: any) => {
  const [choosedEvent, setChoosedEvent] = React.useState("");
  const [filtersAppear, setFiltersApper] = React.useState(false);
  const [modal, setModal] = React.useState(false);
  const [validPassCode, setValidPassCode] = React.useState(false);
  const [errorCode, setErrorCode] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const clickedFilter = (title: string, key: any) => {
    sendMessage(`Filter ${key + 1}`);
    setSecs(25);
  };

  React.useEffect(() => {
    try {
      let queryString: any = qs.parse(location.search, {
        ignoreQueryPrefix: true,
      });
      let eventId: any = parseInt(queryString?.eventId);
      if (!isNaN(eventId)) {
        setChoosedEvent(eventId);
        window.scrollTo(0, 0);
      }
    } catch (error) {
      console.log("Error while parsing eventId");
    }
  }, []);

  React.useEffect(() => {
    if (choosedEvent) {
      getFiltersForOneEvent(choosedEvent, setFiltersApper);
    }
  }, [choosedEvent]);

  React.useEffect(() => {
    setSecs(25);
    if (messaging.message == "Ready") {
      setModal(true);
      return setLoading(false);
    }

    if (messaging.message == "Valid") {
      setValidPassCode(true);
      setErrorCode(false);
      window.scrollTo(0, 0);
      let filterIndex =
        singleEvent &&
        interActiveAds?.length == 0 &&
        singleEvent?.filters?.length;

      if (filterIndex) {
        let customFilter = singleEvent.filters.findIndex(
          (filter: any) => filter.id == 507
        );
        if (customFilter) {
          clickedFilter("name", customFilter);
        } else {
          let filter = singleEvent?.filters[filterIndex - 1];
          if (filter) {
            clickedFilter(filter.name, filterIndex - 1);
          }
        }
      }

      return setLoading(false);
    }

    if (messaging.message == "Wrong") {
      setValidPassCode(false);
      setErrorCode(true);
      return setLoading(false);
    }
  }, [messaging]);

  React.useEffect(() => {
    if (reduxLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [reduxLoading]);

  return (
    <div>
      {loading && <Loader active={loading} />}
      {!validPassCode && (
        <>
          <PassCode
            sendMessage={sendMessage}
            setLoading={setLoading}
            setSecs={setSecs}
            errorCode={errorCode}
            validPassCode={validPassCode}
            loading={loading}
            secs={secs}
          />
        </>
      )}
      {validPassCode && (
        <>
          <SendModal
            modal={modal}
            setModal={setModal}
            sendMessage={sendMessage}
            stopConnection={stopConnection}
            setSecs={setSecs}
          />
          {!loading && !choosedEvent && (
            <>
              <h2 className="my-4 main-text">
                صور الكود اللى على الشاشه بموبايلك
              </h2>
            </>
          )}

          {!loading && singleEvent && (
            <>
              {singleEvent && interActiveAds.length != 0 && (
                <InterActiveComponent
                  sendMessage={sendMessage}
                  loading={loading}
                  setLoading={setLoading}
                  setSecs={setSecs}
                />
              )}

              {filtersAppear && (
                <>
                  <div className="my-5" onClick={() => setSecs(25)}>
                    {singleEvent && interActiveAds.length == 0 && (
                      <Filters
                        singleEvent={singleEvent}
                        sendMessage={sendMessage}
                        setSecs={setSecs}
                        setFiltersApper={setFiltersApper}
                      />
                    )}
                  </div>
                </>
              )}

              <div className="mb-4" style={{ marginTop: "4px" }}>
                {!loading && interActiveAds.length == 0 && (
                  <Actions
                    setFiltersApper={setFiltersApper}
                    sendMessage={sendMessage}
                    setSecs={setSecs}
                    setLoading={setLoading}
                  />
                )}
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  events: state.events?.events,
  interActiveAds: state.events?.interActiveActions,
  singleEvent: state.events?.singleEvent,
  messaging: state.messaging,
  loading: state.events?.loading,
});

const mapDispatchToProps = {
  getFiltersForOneEvent,
};

export default connect(mapStateToProps, mapDispatchToProps)(SplashComponent);
