import {
  EVENTS_LOADED,
  LOAD_ONE_EVENT,
  EVENTS_ERROR,
  GET_INTER_ACTIVE_ACTIONS,
  LODING_EVENTS,
} from "./types";
import axios from "axios";
import { apis } from "../../constants/API";
import AdmZip from "adm-zip";
import JSZip from "jszip";
export const tokenConfig = (getState: any) => {
  //Get token from Local Storage
  let token = getState().auth.token;
  //headers
  const config: any = {
    headers: {
      "Content-type": "application/json",
    },
  };

  return config;
};

export const getEvents = () => (dispatch: any, getState: any) => {
  axios
    .get(apis.events, tokenConfig(getState))
    .then((res) => {
      dispatch({ type: EVENTS_LOADED, payload: res.data.result });
    })
    .catch((error) => {
      console.log(error);
    });
};

export const getFiltersForOneEvent =
  (eventId: number, setFiltersApper?: any) =>
  (dispatch: any, getState: any) => {
    dispatch({ type: LODING_EVENTS });
    axios
      .get(apis.events + "/" + eventId, tokenConfig(getState))
      .then((res) => {
        if (
          res.data.result.title === "Interactive ads" ||
          res.data.result.settings.require_survey == true
        ) {
          if (res.data.result.filters[0]) {
            const filter = res.data.result.filters[0];
            const fileURL = filter.compressed_files_url;
            const jzip = new JSZip();
            let zipData: any = [];

            let readingPromise = new Promise((reslove, reject) => {
              axios
                .get(fileURL, { responseType: "arraybuffer" })
                .then(async (result) => {
                  const file = result.data;

                  //Then I UnZIP The file to multiple files using jszip
                  jzip
                    .loadAsync(file)
                    .then(({ files }) => {
                      //I foor-loop the extracted files to control each file

                      for (let key in files) {
                        //Checking if key exists in obeject *Necessary*

                        if (files.hasOwnProperty(key)) {
                          const innerFile = files[key];

                          //Checking if it is text file

                          if (innerFile.name.includes("txt")) {
                            //Reading the text file

                            innerFile
                              .async("text")
                              .then(async (blob: any) => {
                                //Converting every = to : << this is necessary for parsing it to JSON Format

                                blob = await blob.replaceAll("=", ":");
                                // Data is still text

                                //Converting text data to JSON Format
                                blob = blob
                                  .split(/\r?\n/)
                                  .reduce(function (m: any, i: any) {
                                    var s = i.split(":");
                                    m[s.shift()] = s.join(":");
                                    return m;
                                  }, {});

                                // Now file is parsed to object

                                // Now Finding Related Image to this file
                                const relatedImageName = innerFile.name.replace(
                                  "_Data.txt",
                                  ".png"
                                );

                                let relatedImageInZipFiles = Object.values(
                                  files
                                ).find(
                                  (image) => image.name == relatedImageName
                                );

                                let imageBlob =
                                  await relatedImageInZipFiles.async("blob");

                                let imageFile = new File(
                                  [imageBlob],
                                  relatedImageName,
                                  { type: "image" }
                                );

                                let imageLink = URL.createObjectURL(imageFile);
                                const dataObject = {
                                  textData: blob,
                                  image: imageLink,
                                };

                                reslove(await zipData.push(dataObject));
                              })

                              .catch((error) => {
                                console.log(error);
                              });
                          }
                        }
                      }
                    })

                    .catch((error) => {
                      console.log("ZIP ERROR", error);
                    });
                })
                .catch((fileURLError) => console.log(fileURLError));
            });

            return readingPromise.then(() => {
              return dispatch({
                type: GET_INTER_ACTIVE_ACTIONS,
                payload: zipData,
              });
            });
          }
        } else {
          setFiltersApper && setFiltersApper(true);
          return dispatch({ type: LOAD_ONE_EVENT, payload: res.data.result });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
