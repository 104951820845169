import LoadingOverlay from "react-loading-overlay-ts";
import BeatLoader from "react-spinners/BeatLoader";
import { Modal } from "reactstrap";
export default function MyLoader({ active, children }: any) {
  return (
    <div>
      <LoadingOverlay
        active={active}
        spinner={<BeatLoader size="50px" color="#FD1B92" />}
      >
        <Modal isOpen={active}>{children} </Modal>
      </LoadingOverlay>
    </div>
  );
}
