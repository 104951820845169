import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Row,
} from "reactstrap";

const FormModal = (props: any) => {
  const [showForm, setShowForm] = useState(false);
  const [showSendButton, setShowSendButton] = useState(true);
  const [showMobileButton, setShowMobileButton] = useState(true);

  const [data, setData] = useState<any>({
    username: "",
    mobile: "",
  });

  const [agreed, setAgreed] = useState(true);
  const [finish, setFinish] = useState(false);
  const [errors, setErrors] = useState({
    username: false,
    mobile: false,
  });

  const { setModal, modal, sendMessage } = props;

  const toggle = () => {
    props.setSecs(25);
    if (showForm) {
      sendMessage("CancelSend");
      setTimeout(() => {
        sendMessage("Finish Panel");
      }, 1500);
    } else {
      sendMessage("Cancel");
    }

    setModal(!modal);
    setShowForm(false);
  };

  const chooseSendPhoto = () => {
    props.setSecs(25);
    setShowForm(true);
    sendMessage("ShowSendPopup");
  };

  const changeData = (e: any) => {
    props.setSecs(25);
    const { name, value } = e?.target;
    setData({ ...data, [name]: value });
  };

  const sendToSocket = (e: any) => {
    props.setSecs(25);
    const { username, mobile } = data;

    if (username.length == 0) {
      setErrors({ ...errors, username: true });
      return;
    }

    if (mobile.length != 11) {
      setErrors({ ...errors, mobile: true });
      return;
    }
    sendMessage(`UserData,${username},${mobile}`);
    setFinish(true);
  };

  const handleLastStep = (type: string) => {
    props.setSecs(25);
    sendMessage(type);
    window.location.reload();
  };

  React.useEffect(() => {
    if (data.mobile.length == 11) {
      props.setSecs(25);
      setErrors({ ...errors, mobile: false });
    }
  }, [data.mobile]);

  React.useEffect(() => {
    setShowMobileButton(true);
    setShowSendButton(true);
  }, [modal]);

  function capture() {
    let input: any = document.querySelector("#up-file");
    input.click();
  }

  function getImageDimensions(image: any) {
    return new Promise((resolve, reject) => {
      image.onload = function (e: any) {
        const width = this.width;
        const height = this.height;
        resolve({ height, width });
      };
    });
  }

  let changeSizeImage = async ({ target: imageInput }: any) => {
    try {
      const uploadedImage = imageInput.files[0];
      if (!uploadedImage) {
        // if no file is uploaded, no need to do anything
        return;
      }

      //preview the inputted image
      const inputPreview: any = document.getElementById("input-preview");
      inputPreview.src = URL.createObjectURL(uploadedImage);

      //get the dimensions of the input image
      const { height, width }: any = await getImageDimensions(inputPreview);

      const MAX_WIDTH = 1000;
      const MAX_HEIGHT = 1000;

      const widthRatioBlob: any = await compressImage(
        inputPreview,
        MAX_WIDTH / width,
        width,
        height
      );

      const heightRatioBlob: any = await compressImage(
        inputPreview,
        MAX_HEIGHT / height,
        width,
        height
      );

      //pick the smaller blob between both
      const compressedBlob =
        widthRatioBlob.size > heightRatioBlob.size
          ? heightRatioBlob
          : widthRatioBlob;

      // preview the compressed blob
      let imageURL = URL.createObjectURL(compressedBlob);
      let anchor = document.createElement("a");
      anchor.setAttribute("href", imageURL);
      anchor.setAttribute("download", "yourphoto");
      anchor.click();
    } catch (error) {
      console.error(error);
    }
  };

  function compressImage(
    image: any,
    scale: any,
    initalWidth: any,
    initalHeight: any
  ) {
    return new Promise((resolve, reject) => {
      const canvas = document.createElement("canvas");

      canvas.width = scale * initalWidth;
      canvas.height = scale * initalHeight;

      const ctx = canvas.getContext("2d");
      ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

      ctx.canvas.toBlob((blob) => {
        resolve(blob);
      }, "image/png");
    });
  }

  return (
    <div onClick={() => props.setSecs(25)}>
      <Modal isOpen={modal} toggle={toggle} className="form_send_modal">
        <img id="input-preview" style={{ display: "none" }} />
        <ModalBody className="form_send_modal_body">
          {finish && (
            <Row>
              <Button
                color="primary"
                className="secButton col mx-4"
                onClick={() => handleLastStep("Finish Panel")}
              >
                تمام
              </Button>
            </Row>
          )}

          {!finish && (
            <>
              {!showForm ? (
                <h4 className="main-text">
                  {showMobileButton
                    ? "صورتك جاهزه, عايز تعمل بيها ايه؟"
                    : "صور الصوره دلوقتي من موبايلك"}
                </h4>
              ) : (
                <div className="mx-5">
                  <Input
                    type="text"
                    value={data.username}
                    onChange={changeData}
                    name="username"
                    placeholder="اسمك"
                    className="my-3"
                    invalid={errors.username}
                  />

                  <Input
                    onChange={changeData}
                    value={data.mobile}
                    type="number"
                    name="mobile"
                    placeholder="موبايلك"
                    className="my-3"
                    invalid={errors.mobile}
                  />

                  <div className="row">
                    <Input
                      onChange={() => {
                        sendMessage("ToggleSocial");
                        setAgreed(!agreed);
                      }}
                      checked={agreed}
                      type="checkbox"
                      name="agreed"
                      className="my-3 col-1"
                    />
                    <span
                      className="align-self-center col"
                      style={{ color: "white" }}
                    >
                      موافق على الشروط
                    </span>
                  </div>
                </div>
              )}
            </>
          )}
        </ModalBody>
        {!finish && (
          <ModalFooter className="form_send_modal_foot">
            {showForm ? (
              <Button
                color="primary"
                onClick={sendToSocket}
                className="mainButton"
              >
                ابعت الصوره
              </Button>
            ) : (
              <>
                <input
                  style={{ display: "none" }}
                  type="file"
                  name="image"
                  accept="image/*"
                  capture="environment"
                  id="up-file"
                  onChange={changeSizeImage}
                />
                {showMobileButton && (
                  <Button
                    color="primary"
                    className="mainButton col mx-4"
                    onClick={() => {
                      sendMessage("HideUI");
                      setShowMobileButton(false);
                      setShowSendButton(false);
                      props.setSecs(25);
                      capture();
                      setTimeout(() => {
                        setShowSendButton(true);
                      }, 7000);
                    }}
                  >
                    صورها بموبايلك
                  </Button>
                )}
                {showSendButton && (
                  <Button
                    color="primary"
                    onClick={() => {
                      chooseSendPhoto();
                      setShowSendButton(false);
                      props.setSecs(25);
                    }}
                    className="mainButton col"
                  >
                    ابعتها على الموبايل
                  </Button>
                )}
              </>
            )}
          </ModalFooter>
        )}
      </Modal>
    </div>
  );
};

export default FormModal;
