import { connect } from "react-redux";
import { shutterActions } from "../../constants/shutterActions";
export const Actions = ({
  setFiltersApper,
  setLoading,
  sendMessage,
  setSecs,
}: any) => {
  const handleClickAction = (name: string) => {
    if (name == "Action1") {
      setFiltersApper(true);
    } else {
      setLoading(true);
    }

    sendMessage(name);
    setSecs(90);
  };
  return (
    <div dir="ltr">
      <div className="mb-4" style={{ marginTop: "4px" }}>
        {shutterActions
          ?.filter((filter: any) => filter.name == "Action1")
          .map((action: any, index: any) => {
            return (
              <>
                <img
                  key={index}
                  onClick={() => handleClickAction(action.name)}
                  src={action.logo}
                  className="actionLogo"
                />
              </>
            );
          })}
      </div>
      <div className="row mb-4 px-4">
        {shutterActions
          ?.filter((filter: any) => filter.name != "Action1")
          .map((action: any, index: any) => {
            return (
              <div className="col">
                <img
                  key={index}
                  onClick={() => handleClickAction(action.name)}
                  src={action.logo}
                  className="actionLogo"
                />
              </div>
            );
          })}
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Actions);
