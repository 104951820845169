import { toastr } from "react-redux-toastr";
import {
  AUTH_ERROR,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  USER_LOADED,
  USER_LOADING,
} from "./types";
import axios from "axios";
import { apis } from "../../constants/API";

export const userLodaing = () => ({
  type: USER_LOADING,
});

export const tokenConfig = (getState: any) => {
  //Get token from Local Storage
  let token = getState().auth.token;
  //headers
  const config: any = {
    headers: {
      "Content-type": "application/json",
    },
  };

  if (token) {
    config.headers["Authorization"] = token;
  }
  return config;
};

export const loadUser = () => async (dispatch: any) => {
  dispatch({
    type: USER_LOADING,
  });

  let token = localStorage.getItem("token");
  let user = localStorage.getItem("user");
  if (token) {
    return dispatch({
      type: USER_LOADED,
      payload: { token: token, user: JSON.parse(user) },
    });
  } else {
    return dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const login = (dataForLogin: any) => (dispatch: any) => {
  const config = {
    headers: {
      "Content-type": "application/json",
    },
  };

  axios
    .post(apis.login, dataForLogin, config)
    .then((res) => {
      const user = res.data;
      const token = res.data.api_token;
      dispatch({
        type: LOGIN_SUCCESS,
        payload: { user, token },
      });
      dispatch(loadUser());
      toastr.success("Welcome Back!", "You have successfully logged in");
    })
    .catch((error) => {
      console.log(error?.response?.data.error);
      if (
        error.response?.data &&
        typeof error.response?.data.error[0] == "string"
      ) {
        toastr.error("Login Error", error.response.data.error[0]);
      }

      dispatch({
        type: LOGIN_FAIL,
      });
    });
};
