import {
  EVENTS_ERROR,
  EVENTS_LOADED,
  LODING_EVENTS,
  LOAD_ONE_EVENT,
  GET_INTER_ACTIVE_ACTIONS,
} from "../actions/types";

const initialState: any = {
  events: [],
  error: false,
  loading: true,
  singleEvent: {},
  interActiveActions: [],
};

export default function eventsReducer(state = initialState, action: any) {
  const { type, payload } = action;
  switch (type) {
    case GET_INTER_ACTIVE_ACTIONS:
      return {
        ...state,
        interActiveActions: payload,
        error: false,
        loading: false,
      };
    case EVENTS_LOADED:
      return {
        ...state,
        events: payload,
        error: false,
        loading: false,
      };
    case LODING_EVENTS:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case EVENTS_ERROR:
      return {
        ...state,
        error: true,
        loading: false,
      };
    case LOAD_ONE_EVENT:
      return {
        ...state,
        error: false,
        loading: false,
        singleEvent: payload,
      };
    default:
      return state;
  }
}
