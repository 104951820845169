import React from "react";
import { connect } from "react-redux";
import { Button } from "reactstrap";
import { useHistory } from "react-router-dom";
export const InterActiveComponent = ({
  interActiveAds,
  sendMessage,
  messaging,
  setSecs,
}: any) => {
  const history = useHistory();
  const [loading, setLoading] = React.useState(false);
  const [questionsEnded, setQuestionsEnded] = React.useState(false);
  const [allQuestions, setAllQuestions] = React.useState([]);

  const [currentQuestion, setCuurentQuestion] = React.useState({
    questionNumber: "0",
    answers: [],
  });

  const answerQuestion = (choosedAnswer: any) => {
    setSecs(90);
    setLoading(true);
    sendMessage("Sticker" + choosedAnswer);
  };

  const sotingQuestions = async () => {
    setLoading(true);
    let questions: any[] = [];
    await interActiveAds.map((data: any, i: any) => {
      let questionNumber = data?.textData?.quesNum;
      if (questionNumber) {
        let foundQuestion = questions.find(
          (question: any) => question.questionNumber == questionNumber
        );
        if (!foundQuestion) {
          let questionObject: any = {
            questionNumber: questionNumber,
            answers: [],
          };

          let answers = interActiveAds.filter(
            (data: any) => data?.textData?.quesNum == questionNumber
          );

          questionObject.answers = answers;
          questions.push(questionObject);
        } else {
          console.log("Question Not Found");
        }
      }
    });
    questions = questions.sort(
      ({ questionNumber: a }: any, { questionNumber: b }: any) => a - b
    );
    setCuurentQuestion(questions[0]);
    setAllQuestions(questions);
    setLoading(false);
  };

  React.useEffect(() => {
    sotingQuestions();
  }, []);

  React.useEffect(() => {
    let message = messaging?.message;
    let quesNum = message.split("=")[1];
    setLoading(true);
    const nextQuestion = allQuestions.find(
      (q: any) => parseInt(q.questionNumber) == quesNum
    );
    if (nextQuestion) {
      const ifBatreek = nextQuestion.answers.find(
        (ans: any) => ans.textData.ansNum == -1
      );

      if (ifBatreek) {
        return setCuurentQuestion(null);
      } else {
        if (nextQuestion) {
          setTimeout(() => {
            setCuurentQuestion(nextQuestion);
            setLoading(false);
          }, 500);
        } else {
          setQuestionsEnded(true);
          setLoading(false);
        }
      }
    }
  }, [messaging]);

  return (
    <div>
      {questionsEnded && (
        <div>
          <h1 style={{ color: "white" }}>كده الاسأله كلها حلصت &#128170;</h1>
          <div>
            <Button
              className="mainButton px-5"
              onClick={() => {
                history.push("/");
                window.location.reload();
              }}
            >
              ارجع
            </Button>
          </div>
        </div>
      )}
      {currentQuestion && !loading && !questionsEnded && (
        <>
          <h5 style={{ color: "white" }}>
            {currentQuestion.questionNumber + 1}رقم السؤال
          </h5>

          {!loading &&
            currentQuestion?.answers.map((ans: any, i) => {
              return (
                <img
                  key={i}
                  style={{ cursor: "pointer" }}
                  src={ans.image}
                  onClick={() => answerQuestion(ans?.textData?.ansNum)}
                />
              );
            })}
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  interActiveAds: state.events?.interActiveActions,
  messaging: state.messaging,
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InterActiveComponent);
