import Action1Logo from '../../assets/Home/Group 54.png'
import Action2Logo from '../../assets/Home/Camera.png'
import Action3Logo from '../../assets/Home/boomreng.png'
import Action4Logo from '../../assets/Home/Group 53.png'
import Action5Logo from '../../assets/Home/slow motion button 1.png'
import Action6Logo from '../../assets/Home/CRAZY 1.png'
export const shutterActions: any = [
/*     {
        logo: Action5Logo,
        name: "Action5"
    }, */
    {
        logo: Action6Logo,
        name: "Action6"
    },
    {
        logo: Action3Logo,
        name: "Action3"
    },
    {
        logo: Action1Logo,
        name: "Action1"
    },
    {
        logo: Action2Logo,
        name: "Action2"
    },
/*     {
        logo: Action4Logo,
        name: "Action4"
    } */
]