import React from "react";
import { connect } from "react-redux";
import { getEvents } from "./store/actions/eventsAction";
import store from "./store/store";
import { Row, Button } from "reactstrap";
import SplashComponent from "./Components/Splash";
import qs from "qs";
import { Timer } from "./Components/Timer";
/* import DiscoLogo from "./assets/disco.png"; */
export const Main = ({
  auth,
  started,
  stop,
  sendMessage,
  start,
  location,
}: any) => {
  React.useEffect(() => {
    if (auth?.token) {
      store.dispatch(getEvents());
    }
    try {
      let queryString: any = qs.parse(location.search, {
        ignoreQueryPrefix: true,
      });
      if (queryString?.roomId) {
        let roomId: any = queryString?.roomId;
        start(roomId, true, true);
      }
    } catch (error) {
      console.log("Error while parsing");
    }
  }, []);

  const [secs, setSecs] = React.useState(10);

  return (
    <div dir="rtl">
{/*       <div
        style={{
          background: "light grey",
          justifyContent: "center",
          display: "flex",
        }}
      >
        <div className="mt-4">
          <img src={DiscoLogo} height="100px" />
        </div>
      </div> */}

      {started && (
        <div
          style={{
            background: "light grey",
            justifyContent: "center",
            display: "flex",
          }}
        >
          <div>
            <Timer stopConnection={stop} secs={secs} setSecs={setSecs} />
          </div>
        </div>
      )}

      <div
        className="centeredForm"
        style={{ overflowX: "hidden", marginTop: "-140px" }}
      >
        <Row className="justify-content-center text-center align-content-center">
          {started && (
            <SplashComponent
              sendMessage={sendMessage}
              location={location}
              stopConnection={stop}
              setSecs={setSecs}
              secs={secs}
            />
          )}

          {!started && (
            <div className="px-5">
              <h2 className="my-4 main-text">
                صور الكود اللى على الشاشه بموبايلك{" "}
              </h2>
              <div>
                <Button
                  className="main-button"
                  onClick={() => window.location.reload()}
                >
                  عيد التحميل
                </Button>
              </div>
            </div>
          )}
        </Row>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  auth: state.auth,
  messaging: state.messaging,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Main);
