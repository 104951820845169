import React from "react";
import { connect } from "react-redux";
import { Button, Input } from "reactstrap";
export const PassCode = ({
  sendMessage,
  setLoading,
  setSecs,
  errorCode,
  validPassCode,
  loading,
  secs,
}: any) => {
  const [passCode, setPassCode] = React.useState("");

  const handlePassCode = () => {
    setLoading(true);
    sendMessage("PassCode" + passCode);
    setSecs(25);
  };

  return (
    <div>
      <div className="px-5" onClick={() => setSecs(25)}>
        <h2 className="mb-4  main-text">اكتب الكود الظاهر قدامك في الشاشه</h2>
        <Input
          type="number"
          invalid={errorCode}
          onChange={(e: any) => setPassCode(e.target.value)}
        />

        {!validPassCode && (
          <div>
            <span
              style={{
                color: "white",
                marginTop: "15px",
                fontWeight: "bolder",
                fontSize: "20px",
              }}
            >
              {secs}
            </span>
          </div>
        )}

        <Button
          disabled={loading || passCode.length != 4}
          className=" mx-1 px-5 my-3 mainButton"
          onClick={handlePassCode}
        >
          ادخل
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = ({}: any) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PassCode);
