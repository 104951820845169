import { MESSAGE_RECEIVED } from "../actions/types";

interface MessageArgs {
  Content?: String;
  type?: Number;
}
export const gotNewMessage = (message: MessageArgs) => (dispatch: any) => {
  let content = message.Content;
  console.log("Message From Dispatcher", content);
  const messageArgs = {
    type: MESSAGE_RECEIVED,
    payload: { content, args: message },
  };
  return dispatch(messageArgs);
};
