import "./App.css";
import WebRTC from "./WebRTC";
import { Provider } from "react-redux";
import React from "react";
import store from "./store/store";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ProtectedRoute from "./Components/ProtectedRoute";
import Login from "./Pages/Auth/Login";
import ReduxToastr from "react-redux-toastr";
import { getToken } from "./firebase/config";
function App() {
  const [fbToken, setFbToken] = React.useState(null);
  React.useEffect(() => {
    getToken(setFbToken);
  }, []);
  return (
    <Provider store={store}>
      <ReduxToastr
        timeOut={4000}
        newestOnTop={false}
        preventDuplicates
        position="top-left"
        transitionIn="fadeIn"
        transitionOut="fadeOut"
        progressBar
        closeOnToastrClick
      ></ReduxToastr>

      <Router>
        <Switch>
          <Route exact path="/" component={WebRTC} />
          <Route exact path="/login" component={Login} />
        </Switch>
      </Router>
    </Provider>
  );
}

export default App;
